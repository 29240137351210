.footer-container {
  background-color: #eeeeee;
  color: rgb(0, 0, 0);
  padding: 1rem 8rem;
}
.footer-2nd-row{
  padding: 2em 3rem;
}
.footer-3rd-row ul li{
  font-size: small;
}
.footer-links{
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-links ul {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping for small screens */
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
}
.footer-links a {
  color: #000000;
  text-decoration: none;
  transition: color 0.3s ease;
}
.footer-links a:hover {
  color: rgb(77, 77, 77);
}
.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-links ul > li {
  margin-bottom: 1rem; /* Add spacing for small devices */
}

@media (max-width: 768px) {
  .footer-container{
    padding: 2rem 1rem;
  }
  .footer-links {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  }
  .footer-links ul {
    justify-content: center;
    padding: 0;
  }
  .footer-logo img {
    height: 4rem;
    width: 4rem;
  }
  .footer-2nd-row{
    padding: 0;
  }
  .footer-2nd-row div{
    margin-top: 1em;
  }
  .footer-2nd-row div img{
    margin-top: 13px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');


.main-content{
    /* padding: 0 5em; */
    margin-bottom: 5em;
    
}
.home-content{
    padding: 0 5em;
}
.btn-1{
    background-color: cyan;
    color: rgb(20, 20, 20);
    text-decoration: none;
    font-size: large;
    border: none;
    width: fit-content;
    padding: 10px;
}
.btn-1:hover{
    background-color: rgb(0, 0, 0);
    color: white;
    transition: .2s;
}
.btn-1-type-1{
    min-width: fit-content !important;
    background-color: #282828;
    color: white;
}

.btn-2{
    background-color: white;
    color: #282828;
    text-decoration: none;
    padding: 10px 20px ;
    display: flex;
    justify-content: space-between;
    transition: .4s;
    border: 0;
    align-items: center;
}
.btn-2:hover{
    background-color: rgb(24, 24, 24);
    color: rgb(255, 255, 255);
    transition: .5s;
}
.underlined {
    /*   background: red; */
      position: relative;
    }
    
    .underline-mask:after {
      content: '';
      position: absolute;
      top: 95%;
      width: 150%;
      aspect-ratio: 3 / 1;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 50%;
      border: 6px solid hsl(280 80% 50%);
      /* Use a conic gradient mask to hide and show the bits you want */
      --spread: 140deg;
      --start: 290deg;
      mask: conic-gradient(from var(--start), white 0 var(--spread), transparent var(--spread));
    }
    
    
    .underline-overflow {
      display: inline-block;
      /* A little extra padding and overflow hidden   */
      overflow: hidden;
      padding-bottom: clamp(1rem, 2vmin, 2rem);
    }
    .underline-overflow:after {
      content: '';
      position: absolute;
      top: 100%;
      height: 150%;
      aspect-ratio: 2.5 / 1;
      left: 50%;
      transform: translate(-50%, -10%);
      border-radius: 50%;
      border: 6px solid hsl(10 80% 50%);
    }
    
    .underline-clip:after {
      content: '';
      position: absolute;
      top: 95%;
      width: 150%;
      aspect-ratio: 3 / 1;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 50%;
      border: 6px solid hsl(130 80% 50%);
      /* Use a clip-path to hide and show the bits you want */
      clip-path: polygon(0 0, 50% 50%, 100% 0);
    }


@media screen and (max-width: 768px) {
    .main-content{
        padding: 0;
    }
    .btn-2{
        font-size: large;
        padding: 10px 20px ;
    }
    .home-content{
        padding: 0 10px;
    }
}